@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primaryColor: #faf9f8;
  --primaryPurple: #5F35B8;
  --secPurple: #382486;
  --terPurple: #38248694;
  --secColor: #5F35B8;
  --secColor2: #38248694;
  --terColor: #8259b4;
  --terColor2: #8259b480;
  --secWhite: #e2e2e2e1;
  --mainWhite: #faf9f8;
  --appBg: white;
}

body {
  font-family: 'Helvetica Light', 'Helvetica', 'Open Sans', sans-serif !important;
  color: var(--primaryPurple) !important;
}

.fullPage {
  min-height: 80vh;
}

.link {
  color: white;
}

.quote {
  font-weight: bold;
  font-style: italic;
}

.App {
  background-color: var(--appBg);
  font-family: 'Helvetica Light', 'Helvetica', 'Open Sans', sans-serif !important;
  color: var(--primaryPurple) !important;
  min-height: 100vh;
}

.internal-window {
  background-color: var(--appBg);
  color: var(--mainWhite);
  min-height: 100% !important;
  height: 100% !important;
  padding: 2 !important;
  margin: 0 !important;
}

.footer {
  color: var(--mainWhite) !important;
}

a {
  color: var(--secColor) !important;
  text-decoration: none !important;
}

a:hover {
  color: var(--terColor) !important;
  text-decoration: underline !important;
}

.a2 {
  color: var(--mainWhite) !important;
}

.a2:hover {
  color: var(--secWhite) !important;
  text-decoration: underline !important;
}

.a3 {
  color: black !important;
}

.a3 :hover {
  color: var(--mainWhite) !important;
}

.a4 {
  color: var(--mainWhite) !important;
  font-weight: 10 !important;
}

.a4:hover {
  color: var(--secWhite) !important;
  text-decoration: underline !important;
}

.MuiTypography-root {
  white-space: pre-wrap !important;
  font-family: 'Helvetica Light', 'Helvetica', 'Open Sans', sans-serif !important;
}

.MuiButton-textPrimary {
  white-space: pre-wrap !important;
  font-family: 'Helvetica Light', 'Helvetica', 'Open Sans', sans-serif !important;
}

.MuiPaper-root {
  color: var(--mainWhite) !important;
  background: var(--primaryPurple) !important;
}

.MuiSlider-root {
  color: var(--primaryPurple) !important;
}

.MuiInputLabel-shrink {
  color: var(--primaryPurple) !important;
}

.MuiFormHelperText-root {
  color: var(--primaryPurple) !important;
}

.MuiInputBase-root {
  color: var(--secPurple) !important;
}

.MuiPaginationItem-root {
  background-color: var(--primaryPurple) !important;
  color: var(--mainWhite) !important;
}

.MuiToolbar-root {
  background-color: var(--primaryPurple) !important;
  color: var(--mainWhite);
}

.main-page {
  height: 100vh;
}

.login-form {
  background-color: var(--primaryColor);
  border-radius: 5px;
}

.login-label {
  color: var(--mainWhite) !important;
}

.info-card {
  background-color: var(--primaryColor);
  color: var(--mainWhite) !important;
  border-radius: 5px;
}

.card-text {
  color: var(--mainWhite) !important;
}

.main-button {
  background-color: darkblue;
  border-radius: 10px;
}

.main-button-cancel {
  background-color: red;
  color: white;
  border-radius: 10px;
}

.sec-button {
  background-color: white;
  border-radius: 10px;
}

.main-button:hover {
  background-color: darkcyan;
  border-radius: 10px;
}

.form-area {
  width: max-content;
}

.MuiSelect-select {
  border-color: var(--secColor) !important;
}

.MuiSelect-select:focus {
  border-color: var(--appBg) !important;
}

.MuiIconButton-edgeEnd {
  color: var(--mainWhite) !important;
}

.MuiFormLabel-root {
  color: var(--secColor) !important;
  background: inherit !important;
}

.MuiCard-root {
  color: var(--mainWhite) !important;
}

.MuiDateCalendar-root {
  background-color: var(--terPurple) !important;
}

.MuiPickersDay-root {
  background-color: var(--mainWhite) !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--primaryPurple) !important;
}

.Mui-selected.MuiPaginationItem-page{
  background-color: var(--secColor) !important;
}

.MuiDayCalendar-weekDayLabel {
  color: var(--mainWhite) !important;
}

.ticket-style {
  border-radius: 5px;
  background-color: var(--primaryPurple);
  color: var(--mainWhite);
  padding: 10px;
}

.ticket-bottom-style {
  padding-bottom: 3vw;
  --mask: radial-gradient(2vw at bottom, #0000 97%, #000) 50% / 3.70vw 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.modal-style {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 30% !important;
  background-color: var(--secColor) !important;
  color: var(--mainWhite) !important;
  border: 2px solid #000 !important;
  border-radius: 10px !important;
  box-shadow: 24px !important;
  padding: 20px !important;
}

.recharts-tooltip-wrapper {
  color: var(--primaryPurple);
}

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--primaryPurple) !important;
  background-color: var(--mainWhite) !important;
}

.MuiTab-textColorPrimary{
  color: var(--mainWhite) !important;
}

.mobile-search{
  color: var(--appBg) !important;
}

h3 {
 margin-top: 40px !important;
 margin-bottom: 30px !important;
}